/** @jsx jsx */
import { jsx } from "theme-ui"
import Link from "src/components/Link"
import PropTypes from "prop-types"

const LinkCard = (props) => {
  const Heading = props.headingLevel || "h3"
  return (
    <Link
      to={props.link.link}
      aria-label={props.link.title}
      sx={{
        variant: `cards.${
          props.theme_colour ? props.theme_colour.toLowerCase() : "white"
        }`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        minHeight: ["124px", "124px", "190px"],
        p: [3, null, 5, null, null, 6],
        borderRadius: [5],
      }}
    >
      <Heading
        sx={{
          variant: "cards.title",
        }}
        dangerouslySetInnerHTML={{
          __html: props.link.title,
        }}
      />
    </Link>
  )
}

LinkCard.propTypes = {
  link: PropTypes.shape({
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
}

LinkCard.defaultProps = {
  theme_colour: "red",
}

export default LinkCard
