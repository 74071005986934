/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Section from "src/components/Section"
import SectionNarrow from "src/components/SectionNarrow"
import SectionVertical from "src/components/SectionVertical"

import Container from "src/components/Container"
import Columns from "src/components/Columns"
import Column from "src/components/Column"
import LinkCard from "src/components/LinkCard"

const LinkListHubArticles = (props) => {
  return (
    <SectionVertical
      as="section"
      sx={{
        variant: "section.large",
      }}
    >
      {props.sectionHeading && (
        <Section>
          <Container>
            <h2 sx={{ variant: "text.sectionHeading" }}>
              {props.sectionHeading}
            </h2>
          </Container>
        </Section>
      )}
      <SectionNarrow>
        <Container>
          <Columns sx={{ m: [-1, -1], flexDirection: ["row", "row", "row"] }}>
            {props.links &&
              props.links.map((card, index) => {
                const link = {
                  title: card.title,
                  link: card.uri,
                }
                return (
                  <Column
                    key={index}
                    sx={{
                      p: [1, 1],
                      width: ["100%", "50%", "33.3333%", false, "25%"],
                    }}
                  >
                    <LinkCard link={link} theme_colour={props.themeColor} />
                  </Column>
                )
              })}
          </Columns>
        </Container>
      </SectionNarrow>
    </SectionVertical>
  )
}

LinkListHubArticles.propTypes = {
  sectionHeading: PropTypes.string.isRequired,
  theme: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    })
  ),
}

LinkListHubArticles.defaultProps = {
  theme: "green",
}

export default LinkListHubArticles
